<template>
  <form @submit.prevent="submit">
    <div class="c-form__group u-mb-2">
      <div class="search-input">
        <Select
          ref="suggestCommunities"
          v-model="communityForm"
          name="communuity"
          :options="communitiesFetch"
          placeholder="Communauté"
          select-label=""
          deselect-label=""
          no-result="Aucun résultat"
          track-by="title"
          label="title"
          :show-labels="false"
          :max-height="435"
          :multiple="false"
          :options-limit="10"
          :allow-empty="false"
          :option-height="42"
          clear-on-select
          :internal-search="false"
          open-direction="bottom"
          :show-no-options="selectSearch.length >= 3"
          @update:model-value="onCommunitySelect"
          @search-change="onCommunitySearchChange"
          @request-start="onCommunityRequestStart"
          @request-done="onCommunityRequestDone"
          @request-failed="onCommunityRequestFailed"
        >
          <template #noResult>
            <span>Aucun résultat</span>
          </template>
          <template #option="{ option, search }">
            <div>
              <span v-html="boldenCommunitySuggestion(option, search)" />
            </div>
          </template>
        </Select>
        <img
          src="~/assets/icons/search.svg"
          alt="Rechercher"
        />
      </div>
      <span
        v-if="errors.community"
        class="c-form__feedback is-invalid"
      >
        {{ errors.community }}
      </span>
    </div>
    <div
      v-if="categoriesFetch.length > 0"
      class="c-form__group u-mb-2"
    >
      <div
        class="c-form__wrapper"
        :class="isReviewCategory"
      >
        <select
          id="category"
          v-model="categorySelection"
          name="category"
          class="c-form__select"
          required
          :class="{ 'is-invalid': errors.category }"
          @change="onCategorySelect"
        >
          <option
            :value="{}"
            disabled
            selected
          >
            Catégorie de contenu
          </option>
          <option
            v-for="category in categoriesFetch"
            :key="category.id"
            :value="category"
          >
            {{ category.name }}
          </option>
        </select>
      </div>
      <span
        v-if="errors.category"
        class="c-form__feedback is-invalid"
      >
        {{ errors.category }}
      </span>
    </div>
    <div
      v-if="subcategoriesFetch.length > 0"
      class="c-form__group u-mb-2"
    >
      <select
        id="subcategory"
        v-model="subcategorySelection"
        name="subcategory"
        class="c-form__select"
        required
        :class="{ 'is-invalid': errors.subcategory }"
      >
        <option
          :value="{}"
          disabled
          selected
        >
          Sous-catégorie de contenu
        </option>
        <option
          v-for="subcategory in subcategoriesFetch"
          :key="subcategory.id"
          :value="subcategory"
        >
          {{ subcategory.name }}
        </option>
      </select>
      <span
        v-if="errors.subcategory"
        class="c-form__feedback is-invalid"
      >
        {{ errors.subcategory }}
      </span>
    </div>
    <div v-if="!reviewMode">
      <div class="c-form__group u-mb-2">
        <input
          id="title"
          v-model="form.title"
          type="text"
          class="c-form__control"
          name="title"
          placeholder="Titre du contenu"
          required
        />
        <span
          v-if="errors.title"
          class="c-form__feedback is-invalid"
        >
          {{ errors.title }}
        </span>
      </div>
      <div class="u-mb-2">
        <FilePreview
          post
          :api-error="errors?.image"
          @change="onImageChange"
        />
      </div>
      <div class="u-mb-2">
        <VideoPreview v-model="videoUrl" />
      </div>
      <div class="u-mb-2">
        <TheEditor
          v-model="contentEditor"
          @enter-editor="updateSidebar"
        />
        <span
          v-if="errors.content"
          class="c-form__feedback is-invalid"
        >
          {{ errors.content }}
        </span>
      </div>

      <div class="c-form__group p-tagmanager u-mb-2">
        <div class="search-input">
          <Select
            ref="suggestTags"
            v-model="tagsForm"
            :options="tagsFetch"
            allow-empty
            track-by="name"
            label="name"
            :multiple="false"
            select-label=""
            deselect-label=""
            :clear-on-select="true"
            :options-limit="10"
            no-result="Aucun résultat"
            :placeholder="tagInputPlaceholder"
            :disabled="tagInputDisabled"
            open-direction="bottom"
            :show-no-options="selectSearch.length >= 2"
            @select="onTagSelect"
            @search-change="onTagSearchChange"
            @request-start="onTagRequestStart"
            @request-done="onTagRequestDone"
            @request-failed="onTagRequestFailed"
          >
            <template #noResult><span>Aucun résultat</span></template>
            <template #option="{ option, search }">
              <div>
                <span v-html="boldenTagSuggestion(option, search)" />
              </div>
            </template>
          </Select>
          <span
            v-if="!tagsLoading && tagsForm?.length && tagsForm?.length >= 2"
            class="p-tagmanager__add"
            @click="createTag"
            >Ajouter</span
          >
          <span
            v-if="errors.tag"
            class="c-form__feedback is-invalid"
          >
            {{ errors.tag }}
          </span>
        </div>

        <div
          v-if="tagsSelection && tagsSelection.length"
          class="c-badges u-mt-2"
        >
          <span
            v-for="tag in tagsSelection"
            :key="tag.id"
            class="c-badge"
          >
            {{ tag.name }}
            <span
              arial-label="Supprimer le tag"
              @click="removeTag(tag.id)"
            >
              &times;
            </span>
          </span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="u-mt-4"
    >
      <div class="u-d-f u-jc-sb u-ai-c u-mb-3">
        Note générale :
        <div><InputRating v-model="rating" /></div>
      </div>
      <div class="c-form__group u-mb-2">
        <input
          id="title"
          ref="reviewTitle"
          v-model="form.title"
          type="text"
          class="c-form__control"
          name="title"
          placeholder="Titre de votre avis"
          maxlength="50"
          required
          @focus="
            () => {
              emit('changeSidebar', 'review')
            }
          "
          @blur="() => emit('changeSidebar', 'points')"
        />
        <span
          v-if="errors.title"
          class="c-form__feedback is-invalid"
        >
          {{ errors.title }}
        </span>
      </div>
      <div class="c-form__group u-mb-2">
        <textarea
          id="reviewText"
          ref="reviewText"
          v-model="form.reviewText"
          name="reviewText"
          cols="30"
          rows="10"
          class="c-form__control"
          :placeholder="reviewPlaceholder"
          minlength="140"
          required
          @focus="() => emit('changeSidebar', 'review')"
          @blur="() => emit('changeSidebar', 'points')"
        />
        <span
          v-if="errors.reviewText"
          class="c-form__feedback is-invalid u-d-f"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width="16"
            height="16"
            class="u-mr-2"
          >
            <path
              fill="#ff5050"
              d="M8 16a8 8 0 118-8 8.009 8.009 0 01-8 8zm-.752-5.165V12h1.165v-1.165zm-.024-7.4l.205 6.18h.8l.2-6.18z"
            />
          </svg>
          {{ errors.reviewText }}
        </span>
      </div>
      <!-- additional photo up to 6 + youtube -->
      <div>
        <VideoPreview
          v-if="reviewVideo"
          v-model="videoUrl"
          :restricted="true"
          class="u-mb-2"
        />
        <div
          v-for="(reviewImage, index) in reviewImages"
          :key="`preview-${index}`"
          class="u-mb-2"
        >
          <FilePreview
            :image="reviewImage"
            :name="`reviewImages-${index}`"
            :api-error="errors?.image"
            @change="(data) => onImageChange(data, index)"
          />
        </div>
        <div class="u-d-f u-mb-3">
          <div class="review-actions">
            <span
              v-if="reviewImages.length < 6"
              @click="addReviewImage"
            >
              + Ajouter une photo
            </span>
            <span
              v-if="!reviewVideo"
              @click="addReviewVideo"
            >
              + Ajouter une vidéo
            </span>
          </div>
        </div>
      </div>
      <div class="u-mb-4">
        <p class="u-mb-3">Recommanderiez-vous ce produit à un ami ?</p>
        <div class="o-row">
          <div class="o-col">
            <div class="c-form__block">
              <input
                id="isrecommended-non"
                v-model="isrecommended"
                class="c-form__block-input"
                type="radio"
                name="isrecommended"
                :value="false"
                required
              />
              <label
                class="c-form__block-label"
                for="isrecommended-non"
                data-label="non"
              >
                <span>Non</span>
              </label>
            </div>
          </div>
          <div class="o-col">
            <div class="c-form__block">
              <input
                id="isrecommended-yes"
                v-model="isrecommended"
                class="c-form__block-input"
                type="radio"
                name="isrecommended"
                :value="true"
                required
              />
              <label
                class="c-form__block-label"
                for="isrecommended-yes"
                data-label="oui"
              >
                <span>Oui</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="reviewTypeGame || reviewTypePlatform">
        <hr class="u-my-4" />
        <div
          v-if="reviewTypeGame"
          class="ranges u-mb-4"
        >
          Graphisme du jeu
          <InputRange
            v-model="rating_Graphisme"
            name="rating_Graphisme"
            required
          />
        </div>
        <div
          v-if="reviewTypeGame"
          class="ranges u-mb-4"
        >
          Jouabilité du jeu
          <InputRange
            v-model="rating_Jouabilite"
            name="rating_Jouabilite"
            required
          />
        </div>
        <div
          v-if="reviewTypeGame"
          class="ranges u-mb-4"
        >
          Durée de vie du jeu
          <InputRange
            v-model="rating_DuredeVie"
            name="rating_DuredeVie"
            required
          />
        </div>
        <div
          v-if="reviewTypePlatform"
          class="ranges u-mb-4"
        >
          Design du produit
          <InputRange
            v-model="rating_Design"
            name="rating_Design"
            required
          />
        </div>
        <hr class="u-my-4" />
      </div>
      <div v-if="reviewTypeGame">
        <div
          v-if="reviewTypeGame && multiConsole"
          class="u-mb-4"
        >
          <p class="console__label u-mb-3">
            Sur quelle console avez-vous joué à ce jeu ?
          </p>
          <div class="o-row">
            <div class="o-col">
              <div class="c-form__block">
                <input
                  id="console-ps5"
                  v-model="consoleName"
                  class="c-form__block-input"
                  type="radio"
                  name="console"
                  value="PS5"
                  required
                />
                <label
                  class="c-form__block-label console"
                  for="console-ps5"
                  data-label="ps5"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 990.96 215.56"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M20.79,183C-1,176.77-4.7,163.91,5.26,156.5c9.21-6.87,24.87-12,24.87-12L94.8,121.4v26.32L48.26,164.45c-8.23,3-9.49,7.16-2.8,9.35s18.78,1.57,27-1.39l22.32-8.14v23.55c-1.41.26-3,.51-4.45.75A145.79,145.79,0,0,1,20.79,183Zm136.44,2.74,72.57-25.9c8.24-3,9.5-7.14,2.81-9.33s-18.78-1.57-27,1.4l-48.36,17.06V141.78l2.8-.95a165.71,165.71,0,0,1,33.61-7.13c19.65-2.17,43.71.3,62.59,7.46,21.29,6.74,23.69,16.67,18.28,23.51s-18.67,11.71-18.67,11.71l-98.61,35.49Zm8.49-168.57c38.21,13.19,51.16,29.61,51.16,66.61,0,36.05-22.14,49.72-50.26,36.07V52.62c0-7.88-1.44-15.14-8.8-17.2-5.64-1.82-9.14,3.45-9.14,11.33V215l-45.12-14.4V0c19.19,3.58,47.14,12,62.16,17.13Zm97,184.15a1.64,1.64,0,0,1-.54,1.43,2.84,2.84,0,0,1-1.65.42h-3.47v-3.59h3.68a2.79,2.79,0,0,1,1.2.22,1.53,1.53,0,0,1,.78,1.52Zm2.49,8.18a.79.79,0,0,1-.37-.56,4.21,4.21,0,0,1-.1-1l-.07-1.63a2.93,2.93,0,0,0-.44-1.69,2.73,2.73,0,0,0-.75-.62,3.37,3.37,0,0,0,.95-.93,3.6,3.6,0,0,0,.49-1.94,3.16,3.16,0,0,0-1.85-3.12,5.71,5.71,0,0,0-2.37-.43h-5.81v12.7h2.17v-5.21h3.42a3.3,3.3,0,0,1,1.33.2,1.42,1.42,0,0,1,.71,1.35l.1,2a6.68,6.68,0,0,0,.09,1,1.8,1.8,0,0,0,.16.48l.08.15h2.42v-.74Zm-14.89-5.3a9.51,9.51,0,0,1,2.74-6.71,9.23,9.23,0,0,1,13.22,0,9.52,9.52,0,0,1-6.61,16.19,9.23,9.23,0,0,1-6.61-2.78,9.51,9.51,0,0,1-2.74-6.7Zm9.35,11.4a11.05,11.05,0,0,0,7.94-3.35,11.52,11.52,0,0,0,0-16.12,11.08,11.08,0,0,0-7.94-3.34,11.44,11.44,0,0,0-7.94,19.46,11.05,11.05,0,0,0,7.94,3.35Z"
                      />
                      <path
                        d="M970.58,172.41h-1.92V159.26h-4.4v-1.84H975v1.84h-4.4Zm13.31-3.48h0L988,157.42h3v15H989V159.88h0l-4.55,12.53h-1.22l-4.53-12.53h0v12.53H976.8v-15h3ZM945,42.94H761V92.82s-.56,19.26,23.26,19.26H896.73a31.57,31.57,0,0,1,12.83,2.61c6.28,2.8,13.34,8.5,13.34,20,0,10.32-5,16.18-9.95,19.46a25,25,0,0,1-13.76,4H761v14H915.09s35-1.42,35-37.39c0-18.29-10.68-27.35-20.29-31.79A47,47,0,0,0,910,98.68H798.53a11.24,11.24,0,0,1-5.34-1.27c-2.38-1.29-5-3.85-5-8.89V56.75H945ZM529.41,158.1v14h78.1a38.71,38.71,0,0,0,25.5-9.23,32.27,32.27,0,0,0,11.38-25.36V78a21.5,21.5,0,0,1,8-17A20,20,0,0,1,665,56.75h68.09V42.94H652s-35,.56-35,37.58v55.57A24.12,24.12,0,0,1,613.46,149a18.78,18.78,0,0,1-16.7,9.13ZM349.09,42.94V56.75h139.2a26.28,26.28,0,0,1,11.22,2.41c5.52,2.59,11.72,7.92,11.72,18.73,0,10.5-6.2,15.75-11.72,18.35a26.78,26.78,0,0,1-11.4,2.44H384.87a39.28,39.28,0,0,0-17.64,4c-8.59,4.31-18.14,14.46-18.14,31.2v38h26.79V134.05a19.66,19.66,0,0,1,1.31-7.2c2.21-5.67,7.8-14.77,20.87-14.77h104.1a37.78,37.78,0,0,0,10.36-1.39c9.56-2.72,25.5-10.58,25.5-32.8,0-34.53-34.37-34.95-34.37-34.95Z"
                      />
                    </svg>
                  </span>
                </label>
              </div>
            </div>
            <div class="o-col">
              <div class="c-form__block">
                <input
                  id="console-ps4"
                  v-model="consoleName"
                  class="c-form__block-input"
                  type="radio"
                  name="console"
                  value="PS4"
                  required
                />
                <label
                  class="c-form__block-label console"
                  for="console-ps4"
                  data-label="ps4"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2500"
                      height="521"
                      viewBox="-0.001 0 502.922 104.795"
                    >
                      <title>PlayStation 4</title>
                      <path
                        d="M502.301 24.062H180.797v65.083h322.125V24.062h-.621zm-13.198 57.155h5.627v.832h-2.322v6.418h-.979v-6.418h-2.324l-.002-.832z"
                        fill="none"
                      />
                      <path
                        d="M491.427 88.467h.981v-6.418h2.322v-.832h-5.627v.832h2.324z"
                      />
                      <path
                        d="M502.301 24.062H180.797v65.083h322.125V24.062h-.621zm-6.668 57.155h1.352l2.102 6.191 2.033-6.191h1.183v7.25h-.812v-5.891l-1.953 5.891h-.971l-1.953-5.891v5.891h-.979l-.002-7.25z"
                        fill="none"
                      />
                      <path
                        d="M496.613 82.577l1.953 5.89h.971l1.953-5.89v5.89h.811v-7.25h-1.182l-2.033 6.192-2.102-6.192h-1.351v7.25h.98zM335.361 71.101V41.126c0-6.115 2.854-10.494 8.941-10.494h37.547c.229 0 .451-.228.451-.454v-5.964a.426.426 0 0 0-.035-.152h-45.909c-9.951 1.265-14.812 8.2-14.812 17.064V71.1c0 6.115-2.934 10.494-8.939 10.494h-39.652c-.225 0-.451.152-.451.453v5.965c0 .248.152.438.33.5h45.02c11.732-.308 17.509-7.799 17.509-17.411zM181.104 88.512h13.183a.52.52 0 0 0 .33-.498V68.911c0-6.115 3.454-10.344 8.486-10.344h54.301c12.242 0 18.176-7.55 18.176-17.441 0-8.864-4.856-15.799-14.812-17.064H180.86c-.023.05-.062.089-.062.152v5.964c0 .302.15.454.45.454h71.574c6.084 0 8.938 4.379 8.938 10.494 0 6.116-2.854 10.495-8.938 10.495h-56.628c-8.787 0-15.396 7.476-15.396 17.29v19.104c-.001.245.105.435.306.497z"
                      />
                      <g>
                        <path
                          d="M393.714 74.649h60.466c.221 0 .451-.227.451-.453V36.974c0-.83-.752-.906-1.131-.604l-60.156 37.222c-.382.228-.531.453-.451.756.069.151.3.301.821.301z"
                          fill="none"
                        />
                        <path
                          d="M382.08 81.596h72.101c.221 0 .371.15.371.302v6.116c0 .302.229.453.45.453h12.996c.23 0 .449-.151.449-.453v-5.965c0-.227.23-.453.451-.453h12.916c.301 0 .451-.227.451-.452v-6.041c0-.228-.15-.453-.451-.453h-12.916c-.221 0-.451-.228-.451-.452V30.632c0-3.555-1.32-5.819-3.542-6.57h-3.76c-1.172.301-2.446.84-3.812 1.661l-77.656 47.945c-3.005 1.887-4.058 4-3.306 5.586.601 1.36 2.324 2.342 5.709 2.342zm11.264-8.004L453.5 36.37c.38-.302 1.132-.226 1.132.604v37.224c0 .227-.23.452-.451.452h-60.465c-.521 0-.753-.149-.822-.302-.082-.303.068-.529.45-.756z"
                        />
                      </g>
                      <g>
                        <path
                          d="M50.372 0c5.858 1.092 11.616 2.652 17.347 4.278 3.277.952 6.547 1.937 9.795 2.986 5.102 1.625 10.182 3.465 14.803 6.209 2.07 1.257 4.058 2.677 5.757 4.409a21.957 21.957 0 0 1 4.454 6.255c2.015 4.208 2.705 8.909 2.909 13.531.096 3.016.09 6.058-.474 9.035-.468 2.587-1.3 5.138-2.684 7.385-1.222 1.984-2.921 3.701-4.983 4.807-1.978 1.066-4.242 1.574-6.485 1.529-3.402.035-6.703-1.049-9.756-2.471-.056-10.153-.009-20.308-.024-30.459-.021-1.837.104-3.693-.267-5.505-.261-1.404-.833-2.819-1.936-3.772-.73-.659-1.678-1.031-2.63-1.232-.963-.183-1.99.226-2.586.999-.991 1.238-1.251 2.882-1.296 4.423-.008 27.181.01 54.363-.008 81.542a29319.95 29319.95 0 0 1-21.936-6.961c-.007-32.329-.004-64.658 0-96.988zM17.506 68.784C27.039 65.409 36.562 62 46.099 58.637c.015 3.742 0 7.486.006 11.232-.006.508.026 1.018-.037 1.523-7.538 2.672-15.062 5.391-22.592 8.08-1.044.395-2.115.841-2.92 1.638-.375.374-.681.927-.476 1.462.258.631.902.975 1.496 1.234 1.652.672 3.458.768 5.218.848 1.962-.031 3.933-.178 5.854-.602 1.299-.256 2.549-.693 3.787-1.158 3.218-1.165 6.436-2.337 9.659-3.49.023 3.166.004 6.334.01 9.504-.01.633.029 1.27-.034 1.9-3.958.711-7.963 1.193-11.986 1.257-8.101.183-16.221-1.071-23.916-3.603-2.523-.731-5.031-1.705-7.139-3.307-1.16-.898-2.198-2.027-2.726-3.416-.442-1.177-.41-2.535.141-3.67.531-1.133 1.459-2.027 2.481-2.723 2.364-1.633 4.973-2.879 7.615-3.989 2.279-.97 4.641-1.726 6.966-2.573z"
                        />
                        <path
                          d="M94.209 64.52c2.162-.25 4.338-.309 6.512-.387 7.15.027 14.338.916 21.178 3.062 1.342.408 2.642.937 3.979 1.362 2.384.871 4.77 1.927 6.678 3.641 1.146 1.045 2.135 2.454 2.148 4.062.068 1.31-.524 2.566-1.374 3.531-1.616 1.812-3.752 3.058-5.896 4.144-2.283 1.164-4.737 1.915-7.131 2.801-14.612 5.247-29.226 10.492-43.835 15.745-.003-4.094.004-8.186-.003-12.275.022-.164-.067-.449.163-.492 11.118-3.955 22.233-7.922 33.35-11.889 1.503-.562 3.122-.969 4.397-1.988.522-.41 1.033-1.062.777-1.766-.267-.615-.905-.955-1.492-1.21-1.664-.659-3.476-.786-5.246-.836-2.771.019-5.566.36-8.202 1.249-7.919 2.775-15.823 5.584-23.744 8.35.001-4.395-.003-8.787.002-13.181 5.729-2.011 11.713-3.23 17.739-3.923zM126.692 94.795c1.729-.215 3.535.56 4.571 1.961 1.131 1.447 1.338 3.533.539 5.185-.807 1.714-2.65 2.915-4.56 2.853-1.757.039-3.459-.984-4.324-2.5-.781-1.334-.875-3.043-.27-4.463.681-1.64 2.283-2.836 4.044-3.036zm-.376.924a4.149 4.149 0 0 0-2.425 1.613 4.175 4.175 0 0 0-.694 3.354 4.164 4.164 0 0 0 1.808 2.61c1.403.922 3.37.871 4.713-.141 1.385-.979 2.043-2.857 1.566-4.484-.294-1.1-1.08-2.037-2.07-2.582-.88-.465-1.93-.6-2.898-.37z"
                        />
                        <path
                          d="M125.118 96.896c.901-.005 1.805-.001 2.707-.003.51.007 1.072.126 1.434.518.363.41.393 1.008.293 1.522-.057.34-.356.541-.573.778.188.168.43.324.475.597.104.521-.006 1.067.161 1.577.105.181.212.363.22.578-.374.011-.747.004-1.121.01-.252-.594-.063-1.26-.221-1.871-.062-.249-.32-.404-.565-.396-.61-.025-1.223-.008-1.83-.008-.01.758.021 1.518-.019 2.275a23.39 23.39 0 0 0-.955.001c-.017-1.862-.006-3.72-.006-5.578zm.978.884v1.541c.545.004 1.091.008 1.638.002.271-.002.586-.053.762-.283a.973.973 0 0 0 .015-.98c-.141-.234-.438-.258-.682-.273-.578-.018-1.156-.007-1.733-.007z"
                        />
                      </g>
                    </svg>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="c-form__group u-mb-4">
          <label
            class="c-form__label u-mb-3"
            for="contextdatavalue_CombiendheuresAvezvousJou"
          >
            Combien d'heures avez-vous joué à ce jeu ?
          </label>
          <select
            id="contextdatavalue_CombiendheuresAvezvousJou"
            v-model="contextdatavalue_CombiendheuresAvezvousJou"
            name="contextdatavalue_CombiendheuresAvezvousJou"
            class="c-form__select"
            required
          >
            <option
              value=""
              selected
              disabled
            >
              Sélectionnez une durée
            </option>
            <option
              v-for="duration in referential?.hoursPlayed"
              :key="duration.value"
              :value="duration.value"
            >
              {{ duration.label }}
            </option>
          </select>
        </div>
        <div class="c-form__group u-mb-4">
          <label
            for="contextdatavalue_LieuAchat"
            class="c-form__label"
          >
            Où avez-vous acheté ce jeu ?
          </label>
          <select
            id="contextdatavalue_LieuAchat"
            v-model="contextdatavalue_LieuAchat"
            name="contextdatavalue_LieuAchat"
            class="c-form__select"
            required
          >
            <option
              value=""
              disabled
              selected
            >
              Sélectionnez un lieu
            </option>
            <option
              v-for="place in referential?.choiceMarketPlace"
              :key="place.value"
              :value="place.value"
            >
              {{ place.label }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="reviewTypePlatform">
        <div class="c-form__group u-mb-4">
          <textarea
            id="additionalfield_QuelsSontLesPointsFortsDeCeProduit"
            v-model="additionalfield_QuelsSontLesPointsFortsDeCeProduit"
            name="additionalfield_QuelsSontLesPointsFortsDeCeProduit"
            cols="30"
            rows="10"
            class="c-form__control"
            placeholder="Décrivez les points forts de ce produit"
            required
          />
          <span
            v-if="errors.additionalfield_QuelsSontLesPointsFortsDeCeProduit"
            class="c-form__feedback is-invalid"
          >
            {{ errors.additionalfield_QuelsSontLesPointsFortsDeCeProduit }}
          </span>
        </div>
        <div class="u-mb-4">
          <p class="u-mb-3">Ce produit est-il facile d’utilisation ?</p>
          <div class="o-row">
            <div
              v-for="bool in referential?.choiceYesNo"
              :key="`easy-${bool.value}`"
              class="o-col"
            >
              <div class="c-form__block">
                <input
                  :id="`easy-${bool.value.toLowerCase()}`"
                  v-model="contextdatavalue_CeProduitEstilFacileDutilisation"
                  class="c-form__block-input"
                  type="radio"
                  name="easy"
                  :value="bool.value"
                />
                <label
                  class="c-form__block-label"
                  :for="`easy-${bool.value.toLowerCase()}`"
                  :data-label="bool.value.toLowerCase()"
                >
                  <span>{{ bool.label }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="u-mb-4">
          <p class="u-mb-3">
            Les services disponibles sur ce produit correspondent-ils à vos
            attentes ?
          </p>
          <div class="o-row">
            <div
              v-for="bool in referential?.choiceYesNo"
              :key="`satisfaction-${bool.value}`"
              class="o-col"
            >
              <div class="c-form__block">
                <input
                  :id="`satisfaction-${bool.value.toLowerCase()}`"
                  v-model="serviceProductExpectations"
                  class="c-form__block-input"
                  type="radio"
                  name="satisfaction"
                  :value="bool.value"
                />
                <label
                  class="c-form__block-label"
                  :for="`satisfaction-${bool.value.toLowerCase()}`"
                  :data-label="bool.value.toLowerCase()"
                >
                  <span>{{ bool.label }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="u-mb-4">
          <p class="u-mb-3">
            À quelle offre PlayStation Plus êtes-vous abonné ?
          </p>
          <div class="o-row">
            <div class="o-col u-12">
              <div class="c-form__block u-mb-3">
                <input
                  id="psnp-3"
                  v-model="contextdatavalue_AbonnePlaystationPlus"
                  class="c-form__block-input"
                  type="radio"
                  name="psnp"
                  value="PlaystationPlusPremium"
                  required
                />
                <label
                  class="c-form__block-label c-form__block-label--premium"
                  for="psnp-3"
                >
                  <img
                    src="/img/ps-plus-premium.svg"
                    alt="PlayStation Plus Premium"
                  />
                </label>
              </div>
            </div>
            <div class="o-col u-12">
              <div class="c-form__block u-mb-3">
                <input
                  id="psnp-2"
                  v-model="contextdatavalue_AbonnePlaystationPlus"
                  class="c-form__block-input"
                  type="radio"
                  name="psnp"
                  value="PlaystationPlusExtra"
                />
                <label
                  class="c-form__block-label c-form__block-label--extra"
                  for="psnp-2"
                >
                  <img
                    src="/img/ps-plus-extra.svg"
                    alt="PlayStation Plus Extra"
                  />
                </label>
              </div>
            </div>
            <div class="o-col u-12">
              <div class="c-form__block u-mb-3">
                <input
                  id="psnp-1"
                  v-model="contextdatavalue_AbonnePlaystationPlus"
                  class="c-form__block-input"
                  type="radio"
                  name="psnp"
                  value="Oui"
                />
                <label
                  class="c-form__block-label c-form__block-label--essential"
                  for="psnp-1"
                >
                  <img
                    src="/img/ps-plus-essential.svg"
                    alt="PlayStation Plus Essential"
                  />
                </label>
              </div>
            </div>
            <div class="o-col u-12">
              <div class="c-form__block u-mb-3">
                <input
                  id="psnp-0"
                  v-model="contextdatavalue_AbonnePlaystationPlus"
                  class="c-form__block-input"
                  type="radio"
                  name="psnp"
                  value="Non"
                />
                <label
                  class="c-form__block-label"
                  for="psnp-0"
                  data-label="non"
                >
                  <span>Aucune</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="u-mb-4">
          <p class="u-mb-3">Êtes-vous équipé d’une TV 4K ?</p>
          <div class="o-row">
            <div
              v-for="bool in referential?.choiceYesNo"
              :key="`tv-${bool.value}`"
              class="o-col"
            >
              <div class="c-form__block">
                <input
                  :id="`tv-${bool.value.toLowerCase()}`"
                  v-model="contextdatavalue_EquipementTV4k_1"
                  class="c-form__block-input"
                  type="radio"
                  name="tv"
                  :value="bool.value"
                />
                <label
                  class="c-form__block-label"
                  :for="`tv-${bool.value.toLowerCase()}`"
                  :data-label="bool.value.toLowerCase()"
                >
                  <span>{{ bool.label }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="reviewTypeService">
        <div class="u-mb-4">
          <p class="u-mb-3">Ce service est-il facile d’utilisation ?</p>
          <div class="o-row">
            <div
              v-for="bool in referential?.choiceYesNo"
              :key="`easy2-${bool.value}`"
              class="o-col"
            >
              <div class="c-form__block">
                <input
                  :id="`easy2-${bool.value.toLowerCase()}`"
                  v-model="contextdatavalue_FacileDutilisation"
                  class="c-form__block-input"
                  type="radio"
                  name="easy2"
                  :value="bool.value"
                />
                <label
                  class="c-form__block-label"
                  :for="`easy2-${bool.value.toLowerCase()}`"
                  :data-label="bool.value.toLowerCase()"
                >
                  <span>{{ bool.label }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="u-mb-4">
          <p class="u-mb-3">
            Les services disponibles par ce service correspondent-ils à vos
            attentes ?
          </p>
          <div class="o-row">
            <div
              v-for="bool in referential?.choiceYesNo"
              :key="`satisfaction2-${bool.value}`"
              class="o-col"
            >
              <div class="c-form__block">
                <input
                  :id="`satisfaction2-${bool.value.toLowerCase()}`"
                  v-model="serviceContentExpectations"
                  class="c-form__block-input"
                  type="radio"
                  name="satisfaction2"
                  :value="bool.value"
                />
                <label
                  class="c-form__block-label"
                  :for="`satisfaction2-${bool.value.toLowerCase()}`"
                  :data-label="bool.value.toLowerCase()"
                >
                  <span>{{ bool.label }}</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="u-mb-4">
          <p class="u-mb-3">
            À quelle offre PlayStation Plus êtes-vous abonné ?
          </p>
          <div class="o-row">
            <div class="o-col u-12">
              <div class="c-form__block u-mb-3">
                <input
                  id="psnp-3"
                  v-model="contextdatavalue_AbonnePlaystationPlus"
                  class="c-form__block-input"
                  type="radio"
                  name="psnp"
                  value="PlaystationPlusPremium"
                  required
                />
                <label
                  class="c-form__block-label c-form__block-label--premium"
                  for="psnp-3"
                >
                  <img
                    src="/img/ps-plus-premium.svg"
                    alt="PlayStation Plus Premium"
                  />
                </label>
              </div>
            </div>
            <div class="o-col u-12">
              <div class="c-form__block u-mb-3">
                <input
                  id="psnp-2"
                  v-model="contextdatavalue_AbonnePlaystationPlus"
                  class="c-form__block-input"
                  type="radio"
                  name="psnp"
                  value="PlaystationPlusExtra"
                />
                <label
                  class="c-form__block-label c-form__block-label--extra"
                  for="psnp-2"
                >
                  <img
                    src="/img/ps-plus-extra.svg"
                    alt="PlayStation Plus Extra"
                  />
                </label>
              </div>
            </div>
            <div class="o-col u-12">
              <div class="c-form__block u-mb-3">
                <input
                  id="psnp-1"
                  v-model="contextdatavalue_AbonnePlaystationPlus"
                  class="c-form__block-input"
                  type="radio"
                  name="psnp"
                  value="Oui"
                />
                <label
                  class="c-form__block-label c-form__block-label--essential"
                  for="psnp-1"
                >
                  <img
                    src="/img/ps-plus-essential.svg"
                    alt="PlayStation Plus Essential"
                  />
                </label>
              </div>
            </div>
            <div class="o-col u-12">
              <div class="c-form__block u-mb-3">
                <input
                  id="psnp-0"
                  v-model="contextdatavalue_AbonnePlaystationPlus"
                  class="c-form__block-input"
                  type="radio"
                  name="psnp"
                  value="Non"
                />
                <label
                  class="c-form__block-label"
                  for="psnp-0"
                  data-label="non"
                >
                  <span>Aucune</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="c-form__group">
          <div class="c-form__check">
            <input
              id="consent"
              v-model="consent"
              class="c-form__check-input u-mt-0"
              type="checkbox"
              name="consent"
              required
            />
            <label
              for="consent"
              class="c-form__check-label"
            >
              J'accepte les
              <NuxtLink
                to="/page/conditions-dutilisation"
                target="_blank"
                class="CGU"
                >conditions d'utilisation</NuxtLink
              >
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="footer u-mt-5">
      <div
        v-if="BVError"
        class="is-invalid u-mb-3"
      >
        {{ BVError }}
      </div>
      <div class="u-d-f u-jc-fe">
        <ButtonSimple
          type="submit"
          label="Poster ce contenu"
          :disabled="disabledButton"
        />
      </div>
      <p class="mentions u-mt-5">
        Vous pouvez recevoir des e-mails concernant cette soumission. Les
        e-mails incluront un lien sur lequel vous pouvez cliquer pour vous
        désabonner de futures communications.
      </p>
    </div>
  </form>
</template>

<script setup lang="ts">
import { differenceInYears } from 'date-fns'
import type { SidebarVersion } from '~/modals/contribution'
import type {
  Category6,
  Community3,
  Defaults,
  Endpoints,
  FormData,
  ModalOptions,
  POSTReviewResponse,
  PostContributeResponse,
  Tag,
} from '~/types'
import { API } from '~/utils/constants'
import { reviewSchema, titleSchema } from '~/utils/validations'

type Community = Partial<Endpoints['GET__COMMUNITY_POST_TYPES'][number]>

type ImageReview = {
  preview: string
  alt: string
}

declare global {
  interface Window {
    IGLOO?: {
      enable_rip: boolean
      enable_flash: boolean
      install_flash: boolean
      loader: {
        version: string
        fp_static: boolean
      }
      bb_callback: (blackBoxString: string, isComplete: boolean) => void
    }
  }
}

const props = withDefaults(
  defineProps<{
    defaults?: Defaults
  }>(),
  {
    defaults: () => ({}),
  },
)

const emit = defineEmits<{
  endSubmit: [modalConfig: ModalOptions]
  changeSidebar: [sidebar: SidebarVersion]
}>()

const fingerprint = useState('fingerprint', () => '')

if (!window.IGLOO) {
  /**
   * Bazaar Voice / Iovation fingerprinting configuration object.
   * @see https://developers.bazaarvoice.com/v1.0-ConversationsAPI/docs/iovation-web
   *
   * We can safely access to the window global as this component is
   * dynamically loaded in a modal and will not be server side rendered.
   * The IGLOO object MUST be applied to the window object before the fp.js script is loaded.
   * The fingerprint is stored in a global state variable so that it
   * keep the same variable reference when component is destroyed and re-created.
   */
  window.IGLOO = {
    enable_rip: true, // Enable Real IP protection.
    enable_flash: false, // Disable flash
    install_flash: false, // Don't ask user to install flash
    loader: {
      version: 'general5', // Pins us to 5.x version
      fp_static: false, // Don't load 1st party resources
    },
    bb_callback: (blackBoxString: string, isComplete: boolean) => {
      if (!isComplete) return
      fingerprint.value = encodeURI(blackBoxString)
    },
  }
}

useHead({ script: [{ src: '/scripts/fp.js' }] })

const selectSearch = ref('')

const form = reactive({ title: '', reviewText: '' })

// Community
const communitiesFetch = ref<Endpoints['GET__COMMUNITY_POST_TYPES']>([])
const communityForm = ref<Community>()
const searchLoading = ref(false)

// Category
const categoriesFetch = ref<Category6[]>([])
const categorySelection = ref<Partial<Defaults['category']>>({})

// Subcategory
const subcategoriesFetch = ref<Category6[]>([])
const subcategorySelection = ref<Partial<Defaults['subcategory']>>({})

// Tags
const tagsForm = ref<string>()
const tagsSelection = ref<Tag[]>([])
const image = ref<ImageReview>()
const videoUrl = ref('')
const errors = ref<Record<string, string>>({})
const BVError = ref('')
const loading = ref(false)
const contentEditor = ref('')

// BV fields
const rating = ref(0)
const isrecommended = ref('')
const consoleName = ref<string>('')
const reviewVideo = ref(false)
const reviewImages = ref<ImageReview[]>([])
const rating_Graphisme = ref(0)
const rating_Jouabilite = ref(0)
const rating_DuredeVie = ref(0)
const rating_Design = ref(0)
const contextdatavalue_CombiendheuresAvezvousJou = ref<string>('')
const contextdatavalue_LieuAchat = ref<string>('')
const additionalfield_QuelsSontLesPointsFortsDeCeProduit = ref<string>('')
const contextdatavalue_CeProduitEstilFacileDutilisation = ref<string>('')
const serviceContentExpectations = ref<string>('')
const contextdatavalue_AbonnePlaystationPlus = ref<string>('')
const contextdatavalue_EquipementTV4k_1 = ref<string>('')
const contextdatavalue_FacileDutilisation = ref<string>('')
const serviceProductExpectations = ref<string>('')
const consent = ref(false)

const suggestCommunities = ref<HTMLElement | null>(null)
const suggestTags = ref<HTMLElement | null>(null)
const reviewTitle = ref<HTMLElement | null>(null)
const reviewText = ref<HTMLElement | null>(null)

const referentialStore = useReferentialStore()
const { referential } = storeToRefs(referentialStore)

if (props.defaults.community) {
  const { id, title, type, consoles } = props.defaults.community

  communityForm.value = { id, title, type, consoles }
  communitiesFetch.value = [{ id, title, type, consoles }]

  categoriesFetch.value =
    props.defaults.community?.content_blocks?.flatMap(
      (block) => block.content_block_type?.category ?? [],
    ) ?? []

  if (props.defaults.category) {
    categorySelection.value = {
      ...props.defaults.category,
    }

    subcategoriesFetch.value = props.defaults.category.children ?? []

    if (props.defaults.subcategory) {
      subcategorySelection.value = {
        ...props.defaults.subcategory,
      }
    }
  }
}

const disabledButton = computed(() => {
  if (loading.value) {
    return true
  }

  if (reviewMode.value) {
    return false
  }

  return (
    !communityForm.value?.id ||
    !categorySelection.value?.id ||
    !form.title ||
    !contentEditor.value ||
    contentEditor.value === '<p></p>'
  )
})

const tagInputPlaceholder = computed(() => {
  return tagsSelection.value.length >= 5
    ? 'Limite de 5 tags atteinte'
    : 'Tags associés (facultatif)'
})

const tagInputDisabled = computed(() => {
  return tagsSelection.value.length >= 5
})

const reviewMode = computed(() => {
  return !!categorySelection.value?.is_review
})

const isReviewCategory = computed(() => {
  return reviewMode.value ? 'review' : ''
})

const reviewType = computed(() => {
  return communityForm.value?.type?.slug ?? ''
})

const reviewTypeGame = computed(() => {
  return reviewType.value === 'jeu'
})

const multiConsole = computed(() => {
  return !!communityForm.value?.consoles?.find(
    (console) => console.slug === 'playstation-5',
  )
})

const reviewTypePlatform = computed(() => {
  return reviewType.value === 'console'
})

const reviewTypeService = computed(() => {
  return reviewType.value === 'service'
})

const reviewPlaceholder = computed(() => {
  if (reviewTypeGame.value) return 'Votre avis sur le jeu'
  if (reviewTypePlatform.value) return 'Votre avis sur cette console'
  if (reviewTypeService.value) return 'Votre avis sur ce service'

  return 'Votre avis'
})

const auth = useAuth()
const { gtag } = useGtag()

const submit = async () => {
  loading.value = true

  gtag('event', 'popup_ajout_contenu', {
    cta_name: 'Poster ce contenu',
  })

  if (reviewMode.value) {
    return submitBV()
  }

  if (categoriesFetch.value.length && !categorySelection.value?.id) {
    errors.value.category = 'Veuillez sélectionner une catégorie'

    loading.value = false

    return
  }

  if (subcategoriesFetch.value.length && !subcategorySelection.value?.id) {
    errors.value.category = 'Veuillez sélectionner une sous-catégorie'

    loading.value = false

    return
  }

  const formData: {
    content: string
    [key: string]: string | undefined
  } = {
    content: contentEditor.value,
    contentEmbed: '',
  }

  const images = formData.content.match(
    // eslint-disable-next-line no-useless-escape
    /<img\s[^>]*?src\s*=\s*['\"]([^'\"]*?)['\"][^>]*?>/g,
  )

  if (images?.length) {
    images.forEach((image, index) => {
      formData.content = formData.content.replace(
        image,
        `<!-- contentImage${index + 1} -->`,
      )
      formData[`contentImage${index + 1}`] = image?.match(
        // eslint-disable-next-line no-useless-escape
        /<img\s[^>]*?src\s*=\s*['\"]([^'\"]*?)['\"][^>]*?>/,
      )?.[1]
    })
  }

  const embed = formData.content.match(
    // eslint-disable-next-line no-useless-escape
    /<iframe\s[^>]*?src\s*=\s*['\"]([^'\"]*?)['\"][^>]*?><\/iframe>/,
  )

  if (embed) {
    formData.content = formData.content.replace(
      embed[0],
      '<!-- contentEmbed -->',
    )
    formData['contentEmbed'] = embed[1]
  }

  const { data, error } = await useCustomFetch<PostContributeResponse>(
    API.POST__SECURE_POST_CONTRIBUTE,
    {
      method: 'POST',
      body: {
        community: communityForm.value?.id,
        category: categorySelection.value?.id,
        subCategory: subcategorySelection.value?.id,
        title: form.title,
        image: image.value?.preview ?? '',
        imageLegend: image.value?.alt ?? '',
        video: videoUrl.value,
        ...formData,
        tags: tagsSelection.value.map((t) => t.id),
      },
    },
  )

  const rawData = unref(data)
  if (error.value) {
    const { statusCode, data } = error.value

    if (data.errors && statusCode && statusCode >= 400 && statusCode < 500) {
      Object.keys(data.errors).forEach((key) => {
        errors.value[key] = data.errors[key].join(' ')
      })
    }

    loading.value = false
    return
  }

  if (!rawData) return

  const {
    slug,
    community_slug,
    category_slug,
    sub_category_slug,
    badges_reward,
    points_total,
    ranking,
  } = rawData
  const route = `/communautes/${community_slug}/${category_slug}/${
    sub_category_slug ? `${sub_category_slug}/` : ''
  }${slug}`

  emit('endSubmit', {
    title: `Merci ${auth.data.value?.firstname}`,
    messages: ['Votre contenu est posté sur WAP'],
    badges: badges_reward,
    points: points_total,
    ranking,
    actions: [
      {
        type: 'route',
        route,
        label: 'Voir mon post',
        outline: true,
        tracking: {
          command: 'event',
          eventName: 'popup_ajout_contenu',
          eventParams: {
            cta_name: 'Voir mon post',
          },
        },
      },
      {
        type: 'modal',
        modal: 'contribution',
        label: 'Poster un nouveau contenu',
        tracking: {
          command: 'event',
          eventName: 'cta',
          eventParams: {
            cta_name: 'Poster un nouveau contenu',
          },
        },
      },
    ],
    tracking: {
      command: 'event',
      eventName: 'popupPage',
      eventParams: { popupName: '/popup/ajouter-un-contenu/contenu-ajoute' },
    },
  })

  loading.value = false
  return
}

const submitBV = async () => {
  loading.value = true
  BVError.value = ''

  try {
    errors.value = {}
    await reviewSchema.validate(form.reviewText)
  } catch (err) {
    errors.value = { reviewText: (err as Error)?.message }
    reviewText.value?.scrollIntoView(false)
    loading.value = false

    return
  }

  try {
    errors.value = {}
    await titleSchema.validate(form.title)
  } catch (err) {
    errors.value = { title: (err as Error)?.message }
    reviewTitle.value?.scrollIntoView(false)
    loading.value = false

    return
  }

  const { civility, birthday } = auth.data.value ?? {}

  // calculate user age
  const age = birthday
    ? differenceInYears(new Date(), new Date(birthday))
    : undefined

  const contextdatavalue_Age =
    typeof age === 'number'
      ? referential.value?.choiceRangeAge?.find(
          (item) => item.range[0] <= age && item.range[1] >= age,
        )?.value
      : undefined

  let formData: FormData = {
    productId: communityForm.value?.id,
    rating: rating.value,
    title: form.title,
    reviewText: form.reviewText,
    fp: fingerprint.value,
    contextdatavalue_Age,
    contextdatavalue_Gender: civility,
    isrecommended: isrecommended.value,
  }

  if (reviewTypeGame.value) {
    formData = {
      ...formData,
      rating_Graphisme: rating_Graphisme.value,
      rating_Jouabilite: rating_Jouabilite.value,
      rating_DuredeVie: rating_DuredeVie.value,
      contextdatavalue_CombiendheuresAvezvousJou:
        contextdatavalue_CombiendheuresAvezvousJou.value,
      contextdatavalue_LieuAchat: contextdatavalue_LieuAchat.value,
    }
    if (multiConsole.value) {
      formData = {
        ...formData,
        contextdatavalue_psVersion: consoleName.value,
      }
    }
  } else if (reviewTypePlatform.value) {
    formData = {
      ...formData,
      rating_Design: rating_Design.value,
      additionalfield_QuelsSontLesPointsFortsDeCeProduit:
        additionalfield_QuelsSontLesPointsFortsDeCeProduit.value,
      contextdatavalue_CeProduitEstilFacileDutilisation:
        contextdatavalue_CeProduitEstilFacileDutilisation.value,
      contextdatavalue_LesServicesDisponiblesSurCeProduitCorrespondentilsAVosAttentes_2:
        serviceProductExpectations.value,
      contextdatavalue_AbonnePlaystationPlus:
        contextdatavalue_AbonnePlaystationPlus.value,
      contextdatavalue_EquipementTV4k_1:
        contextdatavalue_EquipementTV4k_1.value,
    }
  } else if (reviewTypeService.value) {
    formData = {
      ...formData,
      contextdatavalue_FacileDutilisation:
        contextdatavalue_FacileDutilisation.value,
      contextdatavalue_LeContenuProposeParCeServiceCorrespondilAVosAttentes:
        serviceContentExpectations.value,
      contextdatavalue_AbonnePlaystationPlus:
        contextdatavalue_AbonnePlaystationPlus.value,
    }
  }

  // generate images and video fields
  if (reviewVideo.value && !!videoUrl.value) {
    formData['videourl_1'] = videoUrl.value
  }

  if (reviewImages.value.length) {
    reviewImages.value.forEach((image, index) => {
      formData[`photourl_${index + 1}`] = image.preview
      formData[`photocaption_${index + 1}`] = image.alt
    })
  }

  const { data, error: postReviewError } =
    await useCustomFetch<POSTReviewResponse>(API.POST__SECURE_POST_BV, {
      method: 'POST',
      body: formData,
    })

  if (data.value) {
    const { badges_reward, ranking, points_total } = data.value

    emit('endSubmit', {
      title: `Merci ${auth.data.value?.firstname}`,
      messages: [
        'Votre avis est transmis aux équipes WAP pour validation.',
        '<strong>Votre avis sera visible sur le site dans 48h environ. Soyez patients 😉</strong>',
      ],
      badges: badges_reward,
      points: points_total,
      ranking,
      actions: [
        {
          type: 'modal',
          modal: 'contribution',
          label: 'Poster un nouveau contenu',
          tracking: {
            command: 'event',
            eventName: 'cta',
            eventParams: { cta_name: 'Poster un nouveau contenu' },
          },
        },
      ],
      tracking: {
        command: 'event',
        eventName: 'popupPage',
        eventParams: { popupName: '/popup/ajouter-un-contenu/contenu-ajoute' },
      },
      incoming: true,
    })
  }

  if (postReviewError.value) {
    const { data, statusCode } = postReviewError.value

    if (data.type === 'ERROR_GENERIC') {
      BVError.value =
        'Une erreur est survenue, veuillez réessayer ultérieurement.'
    } else if (
      data.type === 'ERROR_DUPLICATE_SUBMISSION' ||
      statusCode === 424
    ) {
      BVError.value =
        'Désolé, vous ne pouvez pas poster plusieurs avis sur une même communauté.'
    }

    loading.value = false
  }

  loading.value = false
}

const onCommunitySearchChange = useDebounceFn(async (searchQuery: string) => {
  if (searchQuery.length < 3) {
    if (communitiesFetch.value?.length) {
      communitiesFetch.value = []
    }
    selectSearch.value = searchQuery
    return
  }
  searchLoading.value = true

  const { data, error } = await useCustomFetch<
    Endpoints['GET__COMMUNITY_POST_TYPES']
  >(API.GET__COMMUNITY_POST_TYPES, {
    query: {
      post_type: 'contribute',
      q: searchQuery,
      limit: 10,
    },
  })

  searchLoading.value = false

  const rawData = unref(data)
  if (error.value || !rawData) {
    if (communitiesFetch.value.length) {
      communitiesFetch.value = []
    }
    searchLoading.value = false
    return logError(
      'from contributed content form modal',
      'There was a problem getting input results: ',
      error,
    )
  }

  communitiesFetch.value = rawData
  selectSearch.value = searchQuery
}, 200)

const tagsFetch = ref<Tag[]>([])

const onTagSearchChange = useDebounceFn(async (searchQuery: string) => {
  tagsForm.value = searchQuery
  if (searchQuery.length < 2) {
    if (tagsFetch.value?.length) {
      tagsFetch.value = []
    }
    selectSearch.value = searchQuery
    return
  }
  searchLoading.value = true

  const { data, error } = await useCustomFetch<Endpoints['GET__TAG_SEARCH']>(
    API.GET__TAG_SEARCH,
    {
      query: {
        q: searchQuery,
        limit: 10,
      },
    },
  )
  searchLoading.value = false

  const rawData = unref(data)
  if (error.value || !rawData) {
    if (tagsFetch.value?.length) {
      tagsFetch.value = []
    }
    searchLoading.value = false
    return logError(
      'from contest record modal',
      'There was a problem getting results: ',
      error,
    )
  }

  tagsFetch.value = rawData
  selectSearch.value = searchQuery
}, 200)

// https://wap3.99rdd.io/api/community/post_types?post_type=source&q=duty&limit=10

const boldenCommunitySuggestion = (
  option: Community | undefined,
  searchQuery: string,
) => {
  if (!option) return option

  const { title } = option

  if (!searchQuery || !title) return title ?? ''

  const texts = searchQuery
    .split(/[\s-_/\\|.]/gm)
    .filter((t: string) => !!t) || ['']

  return title.replace(
    new RegExp(`(.*?)(${texts.join('|')})(.*?)`, 'gi'),
    '$1<b>$2</b>$3',
  )
}

const boldenTagSuggestion = (option: Tag | undefined, searchQuery: string) => {
  if (!option) return option

  const { name } = option

  if (!searchQuery || !name) return name ?? ''

  const texts = searchQuery
    .split(/[\s-_/\\|.]/gm)
    .filter((t: string) => !!t) || ['']

  return name.replace(
    new RegExp(`(.*?)(${texts.join('|')})(.*?)`, 'gi'),
    '$1<b>$2</b>$3',
  )
}

const onCommunitySelect = (selectedCommunity: Community3 | null) => {
  categorySelection.value = {}
  subcategorySelection.value = {}

  categoriesFetch.value =
    selectedCommunity?.content_blocks?.flatMap(
      (block) => block?.content_block_type?.category ?? [],
    ) ?? []
}

const onCommunityRequestStart = () => {
  searchLoading.value = true
}

const onCommunityRequestDone = () => {
  searchLoading.value = false
}

const onCommunityRequestFailed = () => {
  searchLoading.value = false
}

const onCategorySelect = () => {
  errors.value.category = ''

  if (!categorySelection.value?.id) return

  const { children } = categorySelection.value

  BVError.value = ''
  subcategorySelection.value = {}

  if (children?.length) {
    subcategoriesFetch.value = children
  } else {
    subcategoriesFetch.value = []
  }
}

const onTagSelect = (suggestionSelected: Tag) => {
  if (!tagsSelection.value) {
    tagsSelection.value = []
  }

  errors.value.tag = ''

  if (
    tagsSelection.value.findIndex((t) => t.id === suggestionSelected?.id) === -1
  ) {
    tagsSelection.value.push(suggestionSelected)
  }

  nextTick(() => {
    tagsForm.value = undefined

    tagsFetch.value = []
  })
}

const tagsLoading = ref(false)

const onTagRequestStart = () => {
  tagsLoading.value = true
}

const onTagRequestDone = () => {
  tagsLoading.value = false
}

const onTagRequestFailed = () => {
  tagsLoading.value = false
}

const createTag = async () => {
  let tagData: Partial<Tag> = {
    id: undefined,
    name: undefined,
  }
  errors.value.tag = ''

  const { data, error } = await useCustomFetch<Tag>(API.POST__SECURE_TAG, {
    // { message: string; errors: Record<string, unknown> }
    method: 'POST',
    body: {
      name: tagsForm.value,
    },
  })

  const rawData = unref(data)
  if (rawData) {
    tagData = { id: rawData.id, name: rawData.name }
  }

  if (error.value) {
    const { data, statusCode } = error.value
    if (statusCode === 400) {
      errors.value.tag = data.errors.name[0] || 'Ce tag est interdit'

      return
    }

    if (statusCode === 409) {
      const { id, name } = data

      tagData = { id, name }
    }
  }

  if (tagData.id && tagData.name) {
    const { id, name } = tagData

    if (tagsSelection.value.findIndex((t) => t.id === id) === -1) {
      tagsSelection.value.push({
        id,
        name,
      })
    }

    nextTick(() => {
      tagsForm.value = undefined
      tagsFetch.value = []
    })
  }
}

const removeTag = (id?: number) => {
  if (id == undefined) return
  tagsSelection.value.splice(
    tagsSelection.value.findIndex((t) => t.id === id),
    1,
  )
}

const onImageChange = (
  imageData: { preview: string; alt: string },
  index: number = -1,
) => {
  if (index < 0) {
    image.value = { ...imageData }

    return
  }

  if (!imageData.preview && !imageData.alt) {
    reviewImages.value = reviewImages.value.filter((_, i) => i !== index)

    return
  }

  reviewImages.value[index] = imageData
}

const addReviewVideo = () => {
  reviewVideo.value = true
}

const addReviewImage = () => {
  // add filepreview fields in dom
  if (reviewImages.value.length < 6) {
    reviewImages.value.push({
      preview: '',
      alt: '',
    })
  }
}

const updateSidebar = (isFocus: boolean) => {
  if (isFocus) {
    emit('changeSidebar', 'contribute')

    return
  }

  emit('changeSidebar', 'points')
}
</script>

<style lang="scss" scoped>
.search-input {
  position: relative;
  width: 100%;
  border: 0.1rem solid #cbcbcb;

  &:hover {
    border: 0.1rem solid #0072ce;
  }

  img {
    position: absolute;
    top: 40%;
    right: 0;
    z-index: 100;
    width: 1rem;
    height: 1rem;
    margin-right: 2rem;
  }
}

.multiselect {
  width: 100%;
  height: 55px;

  &:focus-within {
    box-shadow: 0 0 0 0.35rem rgba(0 67 156 / 0.25);
  }

  :deep(.multiselect__tags) {
    width: 100%;
    height: 100%;
    padding: 1.6rem 2rem;
    font-size: 12px;
    border: 0;
    border-radius: 0;
  }

  :deep(.multiselect__select) {
    display: none;
    pointer-events: none;

    &::before {
      display: none;
      pointer-events: none;
    }
  }

  :deep(.multiselect__single) {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 12px;
  }

  :deep(.multiselect__placeholder) {
    margin: 0;
    padding: 0;
    color: #333;
    font-family: SST;
    font-size: 12px;
  }

  :deep(.multiselect__input) {
    margin: 0;
    padding: 0;
    font-size: 12px;
  }

  :deep(.multiselect__content-wrapper) {
    max-height: 42rem !important;
    font-size: 12px;
    border: 0.1rem solid #cbcbcb;
    border-radius: 0;
  }

  :deep(.multiselect__element) {
    width: 100%;
    font-size: 12px;
    border-bottom: 0.1rem solid #cbcbcb;
  }

  :deep(.multiselect__option) {
    // height: 4.2rem;
    // padding: 0 2rem;
    color: #363636;
    background: #fff;
  }

  :deep(.multiselect__option--highlight) {
    color: #363636;
    background: #fff;
  }

  :deep(.multiselect__option--selected) {
    color: #363636;
    background: #fff;
  }
}

.console__label {
  font-size: 1.5rem;
}

.c-form__block-input:checked + .c-form__block-label {
  span {
    svg {
      filter: brightness(0) invert(1);
    }
  }
}

.c-form__block-label.console {
  span {
    padding-top: 3px;
    text-transform: none;

    svg {
      width: 6.89rem;
      height: 1.5rem;
    }
  }
}

hr {
  margin: 1.5rem 0;
  border-bottom: 0;
  opacity: 0.15;
}

.CGU {
  text-decoration: underline;
  cursor: pointer;
}

.is-invalid {
  color: #ea5151;
}

.footer {
  margin-top: auto;
}

.mentions {
  color: #707070;
  font-size: 1.2rem;
  line-height: 1.2;
}

.p-tagmanager {
  position: relative;

  &__add {
    position: absolute;
    top: 1.7rem;
    right: 2rem;
    color: $blue;
    font-size: 1.2rem;
    cursor: pointer;
  }
}

.review-actions {
  display: flex;
  margin-left: auto;

  span {
    color: $challengeBackgroundBlue;
    font-size: 1.2rem;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:last-of-type {
      margin-left: 2rem;
    }
  }
}

.ranges {
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .c-range {
    margin-top: 0.6rem;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  }
}
</style>
