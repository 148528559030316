<template>
  <form
    class="form-modal"
    @submit.prevent="submit"
  >
    <div v-if="steps.current === 1">
      <div class="c-form__group p-search__group">
        <input
          id="url"
          v-model="form.url"
          type="text"
          class="c-form__control p-search__control"
          name="url"
          placeholder="URL de l'article à partager"
          required
          @input="detectUrl"
        />
        <span
          v-if="form.url !== '' && !loading"
          class="p-search__reset"
          @click="resetUrl"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 15.615 15.615"
          >
            <g
              stroke="#fff"
              stroke-width=".5"
            >
              <path
                d="M2.061.624A1.016 1.016 0 00.624 2.061l12.93 12.93a1.016 1.016 0 101.437-1.437z"
              />
              <path
                d="M.624 13.554a1.016 1.016 0 001.437 1.437l12.93-12.93A1.016 1.016 0 1013.554.624z"
              />
            </g>
          </svg>
        </span>
        <span
          v-if="bannedUrl"
          class="c-form__feedback is-invalid"
        >
          Ce site ne fait pas partie de notre liste de sites autorisés.
          <br />
          <a
            href="mailto:contacts@weareplaystation.fr "
            target="_blank"
            rel="noopener noreferrer"
            >Contactez la Team WAP</a
          >
          pour faire autoriser votre site.
        </span>
        <span
          v-else-if="errors.url"
          class="c-form__feedback is-invalid"
        >
          {{ errors.url }}
        </span>
      </div>
      <div
        v-if="metadata.title"
        class="p-metadata u-mt-4"
      >
        <img
          v-if="metadata.image"
          :src="metadata.image"
          alt=""
          width="226"
          class="p-metadata__image"
        />
        <div>
          <p class="p-metadata__host">{{ metadata.host }}</p>
          <p class="p-metadata__title">{{ metadata.title }}</p>
        </div>
      </div>
      <div
        v-if="loading"
        class="u-mt-5"
      >
        <TheLoader />
      </div>
    </div>
    <div v-if="steps.current === 2">
      <div
        v-if="metadata.title"
        class="p-metadata"
      >
        <img
          v-if="metadata.image"
          :src="metadata.image"
          alt=""
          width="226"
          class="p-metadata__image"
        />
        <div>
          <p class="p-metadata__host">{{ metadata.host }}</p>
          <p class="p-metadata__title">{{ metadata.title }}</p>
        </div>
        <span
          class="p-metadata__edit"
          @click.prevent="goToStep(1)"
        />
      </div>
      <hr />
      <div class="c-form__group u-mb-2">
        <div class="search-input">
          <Select
            ref="suggestCommunities"
            v-model="communityForm"
            :options="communitiesFetch"
            placeholder="Communauté"
            select-label=""
            deselect-label=""
            no-result="Aucun résultat"
            track-by="title"
            label="title"
            :show-labels="false"
            :max-height="435"
            :multiple="false"
            :options-limit="10"
            :allow-empty="false"
            :option-height="42"
            clear-on-select
            :internal-search="false"
            open-direction="bottom"
            :show-no-options="selectSearch.length >= 3"
            @update:model-value="onCommunitySelect"
            @search-change="onCommunitySearchChange"
            @request-start="onCommunityRequestStart"
            @request-done="onCommunityRequestDone"
            @request-failed="onCommunityRequestFailed"
          >
            <template #noResult>
              <span>Aucun résultat</span>
            </template>
            <template #option="{ option, search }">
              <div>
                <span v-html="boldenCommunitySuggestion(option, search)" />
              </div>
            </template>
          </Select>
          <img
            src="~/assets/icons/search.svg"
            alt="Rechercher"
          />
        </div>
        <span
          v-if="errors.community"
          class="c-form__feedback is-invalid"
        >
          {{ errors.community }}
        </span>
      </div>
      <div
        v-if="categoriesFetch.length > 0"
        class="c-form__group u-mb-2"
      >
        <select
          id="category"
          v-model="categorySelection"
          name="category"
          class="c-form__select"
          required
          :class="{ 'is-invalid': errors.category }"
          @change="onCategorySelect"
        >
          <option
            :value="{}"
            disabled
            selected
          >
            Catégorie de contenu
          </option>
          <option
            v-for="category in categoriesFetch"
            :key="category.id"
            :value="category"
          >
            {{ category.name }}
          </option>
        </select>
        <span
          v-if="errors.category"
          class="c-form__feedback is-invalid"
        >
          {{ errors.category }}
        </span>
      </div>
      <div
        v-if="subcategoriesFetch.length > 0"
        class="c-form__group u-mb-2"
      >
        <select
          id="subcategory"
          v-model="subcategorySelection"
          name="subcategory"
          class="c-form__select"
          required
          :class="{ 'is-invalid': errors.subcategory }"
        >
          <option
            :value="{}"
            disabled
            selected
          >
            Sous-catégorie de contenu
          </option>
          <option
            v-for="subcategory in subcategoriesFetch"
            :key="subcategory.id"
            :value="subcategory"
          >
            {{ subcategory.name }}
          </option>
        </select>
        <span
          v-if="errors.subcategory"
          class="c-form__feedback is-invalid"
        >
          {{ errors.subcategory }}
        </span>
      </div>
      <div class="c-form__group p-tagmanager u-mb-2">
        <div class="search-input">
          <Select
            v-model="tagsForm"
            :options="tagsFetch"
            allow-empty
            track-by="name"
            label="name"
            :multiple="false"
            select-label=""
            deselect-label=""
            :clear-on-select="true"
            :options-limit="10"
            no-result="Aucun résultat"
            :placeholder="tagInputPlaceholder"
            :disabled="tagInputDisabled"
            open-direction="bottom"
            :show-no-options="selectSearch.length >= 2"
            @select="onTagSelect"
            @search-change="onTagSearchChange"
            @request-start="onTagRequestStart"
            @request-done="onTagRequestDone"
            @request-failed="onTagRequestFailed"
          >
            <template #noResult><span>Aucun résultat</span></template>
            <template #option="{ option, search }">
              <div>
                <span v-html="boldenTagSuggestion(option, search)" />
              </div>
            </template>
          </Select>
          <span
            v-if="!tagsLoading && tagsForm?.length && tagsForm?.length >= 2"
            class="p-tagmanager__add"
            @click="createTag"
            >Ajouter</span
          >
          <span
            v-if="errors.tag"
            class="c-form__feedback is-invalid"
          >
            {{ errors.tag }}
          </span>
        </div>
        <div
          v-if="tagsSelection && tagsSelection.length"
          class="c-badges u-mt-2"
        >
          <span
            v-for="tag in tagsSelection"
            :key="tag.id"
            class="c-badge"
          >
            {{ tag.name }}
            <span
              arial-label="Supprimer le tag"
              @click="removeTag(tag.id)"
            >
              &times;
            </span>
          </span>
        </div>
      </div>
    </div>
    <div v-if="steps.current === 3">
      <div
        v-if="metadata.title"
        class="p-metadata"
      >
        <img
          v-if="metadata.image"
          :src="metadata.image"
          alt=""
          width="226"
          class="p-metadata__image"
        />
        <div>
          <p class="p-metadata__host">{{ metadata.host }}</p>
          <p class="p-metadata__title">{{ metadata.title }}</p>
        </div>
        <span
          class="p-metadata__edit"
          @click.prevent="goToStep(1)"
        />
      </div>
      <hr />
      <div class="info">
        {{ communityForm?.title }}
        <span
          class="info__edit"
          @click.prevent="goToStep(2)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14.91 14.854"
          >
            <path
              fill="#a5a5a5"
              d="M14.624 3.061L11.861.315l-.05-.05A.908.908 0 0010.62.182a1.07 1.07 0 00-.149.132L1.342 9.429a.975.975 0 00-.281.695c-.017.05-.05.1-.066.149l-.98 3.736a.943.943 0 00-.017.215.561.561 0 00.165.447.592.592 0 00.414.182h.1a.621.621 0 00.165-.017l3.755-.893a.513.513 0 00.215-.1 1 1 0 00.678-.281l9.13-9.13a.963.963 0 00.265-.893 1.258 1.258 0 00-.261-.478zM10.985.709a.249.249 0 01.182-.066.266.266 0 01.182.083l.05.05 2.746 2.746.017.017a.28.28 0 010 .38l-1.274 1.274a.28.28 0 01-.38 0l-2.813-2.81a.241.241 0 010-.364zm.877 5.524a.28.28 0 01-.38 0L8.669 3.425a.28.28 0 010-.38l.43-.43a.778.778 0 00.149.2l2.812 2.812a.746.746 0 00.232.165zm-10.04 7.711l-.877-.893.546-2.1 2.481 2.481zm3.209-.844a.339.339 0 01-.48 0l-2.746-2.745a.327.327 0 01-.1-.232.3.3 0 01.1-.232l6.252-6.252a2.155 2.155 0 00.165.248l2.812 2.812a.654.654 0 00.248.165z"
            />
          </svg>
        </span>
      </div>
      <hr />
      <div class="info">
        {{ categorySelection?.name }}
        <span
          class="info__edit"
          @click.prevent="goToStep(2)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14.91 14.854"
          >
            <path
              fill="#a5a5a5"
              d="M14.624 3.061L11.861.315l-.05-.05A.908.908 0 0010.62.182a1.07 1.07 0 00-.149.132L1.342 9.429a.975.975 0 00-.281.695c-.017.05-.05.1-.066.149l-.98 3.736a.943.943 0 00-.017.215.561.561 0 00.165.447.592.592 0 00.414.182h.1a.621.621 0 00.165-.017l3.755-.893a.513.513 0 00.215-.1 1 1 0 00.678-.281l9.13-9.13a.963.963 0 00.265-.893 1.258 1.258 0 00-.261-.478zM10.985.709a.249.249 0 01.182-.066.266.266 0 01.182.083l.05.05 2.746 2.746.017.017a.28.28 0 010 .38l-1.274 1.274a.28.28 0 01-.38 0l-2.813-2.81a.241.241 0 010-.364zm.877 5.524a.28.28 0 01-.38 0L8.669 3.425a.28.28 0 010-.38l.43-.43a.778.778 0 00.149.2l2.812 2.812a.746.746 0 00.232.165zm-10.04 7.711l-.877-.893.546-2.1 2.481 2.481zm3.209-.844a.339.339 0 01-.48 0l-2.746-2.745a.327.327 0 01-.1-.232.3.3 0 01.1-.232l6.252-6.252a2.155 2.155 0 00.165.248l2.812 2.812a.654.654 0 00.248.165z"
            />
          </svg>
        </span>
      </div>
      <hr />
      <div v-if="!!subcategorySelection && subcategorySelection.id">
        <div class="info">
          {{ subcategorySelection.name }}
          <span
            class="info__edit"
            @click.prevent="goToStep(2)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 14.91 14.854"
            >
              <path
                fill="#a5a5a5"
                d="M14.624 3.061L11.861.315l-.05-.05A.908.908 0 0010.62.182a1.07 1.07 0 00-.149.132L1.342 9.429a.975.975 0 00-.281.695c-.017.05-.05.1-.066.149l-.98 3.736a.943.943 0 00-.017.215.561.561 0 00.165.447.592.592 0 00.414.182h.1a.621.621 0 00.165-.017l3.755-.893a.513.513 0 00.215-.1 1 1 0 00.678-.281l9.13-9.13a.963.963 0 00.265-.893 1.258 1.258 0 00-.261-.478zM10.985.709a.249.249 0 01.182-.066.266.266 0 01.182.083l.05.05 2.746 2.746.017.017a.28.28 0 010 .38l-1.274 1.274a.28.28 0 01-.38 0l-2.813-2.81a.241.241 0 010-.364zm.877 5.524a.28.28 0 01-.38 0L8.669 3.425a.28.28 0 010-.38l.43-.43a.778.778 0 00.149.2l2.812 2.812a.746.746 0 00.232.165zm-10.04 7.711l-.877-.893.546-2.1 2.481 2.481zm3.209-.844a.339.339 0 01-.48 0l-2.746-2.745a.327.327 0 01-.1-.232.3.3 0 01.1-.232l6.252-6.252a2.155 2.155 0 00.165.248l2.812 2.812a.654.654 0 00.248.165z"
              />
            </svg>
          </span>
        </div>
        <hr />
      </div>
      <div class="info">
        <div
          v-if="tagsSelection.length"
          class="c-badges"
        >
          <span
            v-for="tag in tagsSelection"
            :key="tag.id"
            class="c-badge"
          >
            {{ tag.name }}
          </span>
        </div>
        <div v-else>Aucun tag</div>
        <span
          class="info__edit"
          @click.prevent="goToStep(2)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 14.91 14.854"
          >
            <path
              fill="#a5a5a5"
              d="M14.624 3.061L11.861.315l-.05-.05A.908.908 0 0010.62.182a1.07 1.07 0 00-.149.132L1.342 9.429a.975.975 0 00-.281.695c-.017.05-.05.1-.066.149l-.98 3.736a.943.943 0 00-.017.215.561.561 0 00.165.447.592.592 0 00.414.182h.1a.621.621 0 00.165-.017l3.755-.893a.513.513 0 00.215-.1 1 1 0 00.678-.281l9.13-9.13a.963.963 0 00.265-.893 1.258 1.258 0 00-.261-.478zM10.985.709a.249.249 0 01.182-.066.266.266 0 01.182.083l.05.05 2.746 2.746.017.017a.28.28 0 010 .38l-1.274 1.274a.28.28 0 01-.38 0l-2.813-2.81a.241.241 0 010-.364zm.877 5.524a.28.28 0 01-.38 0L8.669 3.425a.28.28 0 010-.38l.43-.43a.778.778 0 00.149.2l2.812 2.812a.746.746 0 00.232.165zm-10.04 7.711l-.877-.893.546-2.1 2.481 2.481zm3.209-.844a.339.339 0 01-.48 0l-2.746-2.745a.327.327 0 01-.1-.232.3.3 0 01.1-.232l6.252-6.252a2.155 2.155 0 00.165.248l2.812 2.812a.654.654 0 00.248.165z"
            />
          </svg>
        </span>
      </div>
      <hr />
    </div>
    <div class="footer">
      <div
        v-if="steps.total > 1"
        class="steps"
      >
        {{ steps.current }} / {{ steps.total }}
      </div>
      <div class="footer__button">
        <ButtonSimple
          type="submit"
          :label="steps.buttonLabel"
          :disabled="disabledButton"
        />
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import { decode } from 'html-entities'
import 'vue-multiselect/dist/vue-multiselect.css'
import type { ValidationError } from 'yup'
import { string } from 'yup'
import type { SidebarVersion } from '~/modals/contribution'
import type { Category6, Community3, Defaults, Tag } from '~/types'
import type { ModalOptions, PostContributeResponse } from '~/types/contribution'
import type { Endpoints } from '~/types/endpoints'

type Community = Partial<Endpoints['GET__COMMUNITY_POST_TYPES'][number]>

const props = withDefaults(
  defineProps<{
    defaults?: Defaults
  }>(),
  {
    defaults: () => ({}),
  },
)

const emit = defineEmits<{
  endSubmit: [modalConfig: ModalOptions]
  changeSidebar: [sidebar: SidebarVersion]
}>()

const suggestCommunities = ref<HTMLElement | null>(null)

const communitiesFetch = ref<Endpoints['GET__COMMUNITY_POST_TYPES']>([])
const communityForm = ref<Community>()

const categoriesFetch = ref<Category6[]>([])
const categorySelection = ref<Partial<Defaults['category']>>({})
const subcategoriesFetch = ref<Category6[]>([])
const subcategorySelection = ref<Partial<Defaults['subcategory']>>({})

if (props.defaults.community) {
  const { id, title } = props.defaults.community

  communityForm.value = { id, title }
  communitiesFetch.value = [{ id, title }]

  categoriesFetch.value =
    props.defaults.community?.content_blocks?.flatMap(
      (block) => block.content_block_type?.category ?? [],
    ) ?? []

  if (props.defaults.category) {
    categorySelection.value = {
      ...props.defaults.category,
    }

    subcategoriesFetch.value = props.defaults.category.children ?? []

    if (props.defaults.subcategory) {
      subcategorySelection.value = {
        ...props.defaults.subcategory,
      }
    }
  }
}

const searchLoading = ref(false)

const onCommunitySearchChange = useDebounceFn(async (searchQuery: string) => {
  if (searchQuery.length < 3) {
    if (communitiesFetch.value?.length) {
      communitiesFetch.value = []
    }
    selectSearch.value = searchQuery
    return
  }
  searchLoading.value = true

  const { data, error } = await useCustomFetch<
    Endpoints['GET__COMMUNITY_POST_TYPES']
  >(API.GET__COMMUNITY_POST_TYPES, {
    query: {
      post_type: 'source',
      q: searchQuery,
      limit: 10,
    },
  })

  searchLoading.value = false

  const rawData = unref(data)
  if (error.value || !rawData) {
    if (communitiesFetch.value.length) {
      communitiesFetch.value = []
    }
    searchLoading.value = false
    return logError(
      'from contest record modal',
      'There was a problem getting results: ',
      error,
    )
  }

  communitiesFetch.value = rawData
  selectSearch.value = searchQuery
}, 200)

const tagsFetch = ref<Tag[]>([])

const onTagSearchChange = useDebounceFn(async (searchQuery: string) => {
  tagsForm.value = searchQuery
  if (searchQuery.length < 2) {
    if (tagsFetch.value?.length) {
      tagsFetch.value = []
    }
    selectSearch.value = searchQuery
    return
  }
  searchLoading.value = true

  const { data, error } = await useCustomFetch<Endpoints['GET__TAG_SEARCH']>(
    API.GET__TAG_SEARCH,
    {
      query: {
        q: searchQuery,
        limit: 10,
      },
    },
  )
  searchLoading.value = false

  const rawData = unref(data)
  if (error.value || !rawData) {
    if (tagsFetch.value?.length) {
      tagsFetch.value = []
    }
    searchLoading.value = false
    return logError(
      'from contest record modal',
      'There was a problem getting results: ',
      error,
    )
  }

  tagsFetch.value = rawData
  selectSearch.value = searchQuery
}, 200)

const steps = ref({
  current: 1,
  total: 3,
  buttonLabel: 'Suivant',
})
const metadata = ref<{
  title?: string
  url?: string
  description?: string
  host?: string
  image?: string
}>({})
const form = ref({
  url: '',
})
const loading = ref(false)

const disabledButton = computed(() => {
  if (loading.value) {
    return true
  }

  if (steps.value.current === 1) {
    return !(
      !!metadata.value &&
      !!metadata.value.title &&
      metadata.value.url === form.value.url
    )
  }

  if (steps.value.current === 2) {
    return !communityForm.value?.id
  }

  return false
})

const tagsSelection = ref<Tag[]>([])

const tagInputPlaceholder = computed(() => {
  return tagsSelection.value && tagsSelection.value.length >= 5
    ? 'Limite de 5 tags atteinte'
    : 'Tags associés (facultatif)'
})

const tagInputDisabled = computed(() => {
  return tagsSelection.value && tagsSelection.value.length >= 5
})

const selectSearch = ref('')

const urlSchema = string()
  .required('Le champ est requis')
  .url('Cette URL n’est pas valide.')
const bannedUrl = ref(false)
const errors = ref<Record<string, string>>({})

const detectUrl = async () => {
  metadata.value = {}

  try {
    errors.value = {}
    await urlSchema.validate(form.value.url)
  } catch (err) {
    const error = err as ValidationError
    errors.value.url = error.message
    return
  }

  if (form.value.url.match(/\.php$/) !== null) {
    errors.value.url =
      "Pour des raisons de sécurité, vous ne pouvez pas poster ce type d'URL."
    return
  }

  loading.value = true
  bannedUrl.value = false

  const { data, error } = await useCustomFetch<{
    is_allowed?: boolean
    is_duplicate?: boolean
  }>(API.GET__ALL0W_WEBSITE_CHECK, {
    query: {
      url: encodeURIComponent(form.value.url),
      type: 'source',
      limit: 10,
    },
  })

  const rawData = unref(data)
  if (error.value || !rawData) {
    errors.value.url =
      error.value?.data.message ??
      'Une erreur est survenue lors de la récupération des informations, veuillez réessayer ultérieurement.'
    loading.value = false
    return
  }

  if (!rawData.is_allowed) {
    errors.value.url =
      'Il est interdit de poster des contenus provenant de ce site.'
    bannedUrl.value = true
    loading.value = false

    return
  }

  if (rawData.is_duplicate) {
    errors.value.url = 'Désolé, ce contenu a déjà été posté.'

    loading.value = false
    return
  }

  const linkPreview = useVideoLinkPreview(form.value.url)
  const { data: crawlData, error: crawlError } = await useCustomFetch<{
    url: string
    meta: { title: string; description: string }
    links: { thumbnail?: { href: string }[] }
  }>(linkPreview)

  if (crawlError.value || !crawlData.value) {
    errors.value.url = 'Aucune information trouvée.'

    loading.value = false
    return
  }

  const {
    url,
    meta: { title, description },
    links: { thumbnail },
  } = crawlData.value

  const host = new URL(url as string)?.host
  const image = thumbnail?.[0]?.href ?? ''

  metadata.value = {
    title: decode(title).replace(/(<([^>]+)>)/gi, ''),
    description,
    url,
    host,
    image,
  }

  loading.value = false
}

const goToStep = (step: number) => {
  steps.value.current = step

  if (step < 3) {
    steps.value.buttonLabel = 'Suivant'
  } else {
    steps.value.buttonLabel = 'Poster ce contenu'
  }
}

const auth = useAuth()

const boldenCommunitySuggestion = (
  option: Community | undefined,
  searchQuery: string,
) => {
  if (!option) return option

  const { title } = option

  if (!searchQuery || !title) return title ?? ''

  const texts = searchQuery
    .split(/[\s-_/\\|.]/gm)
    .filter((t: string) => !!t) || ['']

  return title.replace(
    new RegExp(`(.*?)(${texts.join('|')})(.*?)`, 'gi'),
    '$1<b>$2</b>$3',
  )
}

const boldenTagSuggestion = (option: Tag | undefined, searchQuery: string) => {
  if (!option) return option

  const { name } = option

  if (!searchQuery || !name) return name ?? ''

  const texts = searchQuery
    .split(/[\s-_/\\|.]/gm)
    .filter((t: string) => !!t) || ['']

  return name.replace(
    new RegExp(`(.*?)(${texts.join('|')})(.*?)`, 'gi'),
    '$1<b>$2</b>$3',
  )
}

const onCommunitySelect = (selectedCommunity: Community3 | null) => {
  categorySelection.value = {}
  subcategorySelection.value = {}

  categoriesFetch.value =
    selectedCommunity?.content_blocks?.flatMap(
      (block) => block?.content_block_type?.category ?? [],
    ) ?? []
}

const removeTag = (id?: number) => {
  if (id == undefined) return
  tagsSelection.value.splice(
    tagsSelection.value.findIndex((t) => t.id === id),
    1,
  )
}

const { gtag } = useGtag()

const submit = async () => {
  if (steps.value.current === 1) {
    if (!!metadata.value.title && metadata.value.url === form.value.url) {
      return goToStep(2)
    }
    return
  }

  if (steps.value.current === 2) {
    errors.value = {}

    if (categoriesFetch.value.length && !categorySelection.value?.id) {
      errors.value.category = 'Veuillez sélectionner une catégorie'

      return
    }

    if (subcategoriesFetch.value.length && !subcategorySelection.value?.id) {
      errors.value.subcategory = 'Veuillez sélectionner une sous-catégorie'

      return
    }

    return goToStep(3)
  }

  if (steps.value.current === 3) {
    loading.value = true

    gtag('event', 'popup_ajout_contenu', {
      cta_name: 'Poster ce contenu',
    })

    const { data, error } = await useCustomFetch<PostContributeResponse>(
      API.POST__SECURE_POST_SOURCE,
      {
        method: 'POST',
        body: {
          community: communityForm.value?.id,
          category: categorySelection.value?.id,
          subCategory: subcategorySelection.value?.id,
          title: metadata.value.title,
          content: metadata.value.description,
          link: metadata.value.url,
          tags: tagsSelection.value.map((t) => t.id),
        },
      },
    )

    const rawData = unref(data)
    if (error.value || !rawData) {
      console.error(error.value?.data)
      loading.value = false
      return
    }

    const {
      slug,
      community_slug,
      category_slug,
      sub_category_slug,
      badges_reward,
      points_total,
      ranking,
    } = rawData

    const route = `/communautes/${community_slug}/${category_slug}/${
      sub_category_slug ? `${sub_category_slug}/` : ''
    }${slug}`

    emit('endSubmit', {
      title: `Merci ${auth.data.value?.firstname}`,
      messages: ['Votre contenu est posté sur WAP'],
      badges: badges_reward,
      points: points_total,
      ranking,
      actions: [
        {
          type: 'route',
          route,
          label: 'Voir mon post',
          outline: true,
          tracking: {
            command: 'event',
            eventName: 'cta',
            eventParams: {
              cta_name: 'Voir mon post',
            },
          },
        },
        {
          type: 'modal',
          modal: 'contributionModal',
          label: 'Poster un nouveau contenu',
          tracking: {
            command: 'event',
            eventName: 'cta',
            eventParams: {
              cta_name: 'Poster un nouveau contenu',
            },
          },
        },
      ],
      tracking: {
        command: 'event',
        eventName: 'popupPage',
        eventParams: {
          page: '/popup/ajouter-un-contenu/contenu-ajoute',
        },
      },
    } satisfies ModalOptions)

    loading.value = false
  }
}

const resetUrl = () => {
  form.value.url = ''
  metadata.value = {}
  bannedUrl.value = false
  errors.value = {}
}

const onCommunityRequestStart = () => {
  searchLoading.value = true
}

const onCommunityRequestDone = () => {
  searchLoading.value = false
}

const onCommunityRequestFailed = () => {
  searchLoading.value = false
}

const onCategorySelect = () => {
  errors.value.category = ''

  if (!categorySelection.value?.id) return

  const { children } = categorySelection.value

  subcategorySelection.value = {}

  if (children?.length) {
    subcategoriesFetch.value = children
  } else {
    subcategoriesFetch.value = []
  }
}

const tagsForm = ref<string>()

const onTagSelect = (suggestionSelected: Tag) => {
  if (!tagsSelection.value) {
    tagsSelection.value = []
  }
  errors.value.tag = ''

  if (
    tagsSelection.value.findIndex((t) => t.id === suggestionSelected?.id) === -1
  ) {
    tagsSelection.value.push(suggestionSelected)
  }

  nextTick(() => {
    tagsForm.value = undefined

    tagsFetch.value = []
  })
}

const tagsLoading = ref(false)

const onTagRequestStart = () => {
  tagsLoading.value = true
}

const onTagRequestDone = () => {
  tagsLoading.value = false
}

const onTagRequestFailed = () => {
  tagsLoading.value = false
}

const createTag = async () => {
  let tagData: Partial<Tag> = {
    id: undefined,
    name: undefined,
  }

  errors.value.tag = ''

  const { data, error } = await useCustomFetch<Tag>(API.POST__SECURE_TAG, {
    method: 'POST',
    body: {
      name: tagsForm.value,
    },
  })

  const rawData = unref(data)
  if (rawData) {
    tagData = { id: rawData.id, name: rawData.name }
  }

  if (error.value) {
    if (error.value.statusCode === 400) {
      errors.value.tag =
        error.value.data.errors.name[0] ?? 'Ce tag est interdit'

      errors.value.tag =
        error.value.data.errors.name[0] ?? 'Ce tag est interdit'

      return
    }

    if (error.value.statusCode === 409) {
      const { id, name } = error.value.data

      tagData = { id, name }
    }
  }

  if (tagData.id && tagData.name) {
    const { id, name } = tagData

    if (tagsSelection.value.findIndex((t) => t.id === id) === -1) {
      tagsSelection.value.push({
        id,
        name,
      })
    }

    nextTick(() => {
      tagsForm.value = undefined
      tagsFetch.value = []
    })
  }
}
</script>

<style lang="scss" scoped>
.form-modal {
  @media (max-width: 699px) {
    padding-bottom: 10rem;
  }

  .search-input {
    position: relative;
    width: 100%;
    border: 0.1rem solid #cbcbcb;

    &:hover {
      border: 0.1rem solid #0072ce;
    }

    img {
      position: absolute;
      top: 40%;
      right: 0;
      z-index: 100;
      width: 1rem;
      height: 1rem;
      margin-right: 2rem;
    }
  }

  .multiselect {
    width: 100%;
    height: 55px;

    &:focus-within {
      box-shadow: 0 0 0 0.35rem rgba(0 67 156 / 0.25);
    }

    :deep(.multiselect__tags) {
      width: 100%;
      height: 100%;
      padding: 1.6rem 2rem;
      font-size: 12px;
      border: 0;
      border-radius: 0;
    }

    :deep(.multiselect__select) {
      display: none;
      pointer-events: none;

      &::before {
        display: none;
        pointer-events: none;
      }
    }

    :deep(.multiselect__single) {
      width: 100%;
      margin: 0;
      padding: 0;
      font-size: 12px;
    }

    :deep(.multiselect__placeholder) {
      margin: 0;
      padding: 0;
      color: #333;
      font-family: SST;
      font-size: 12px;
    }

    :deep(.multiselect__input) {
      margin: 0;
      padding: 0;
      font-size: 12px;
    }

    :deep(.multiselect__content-wrapper) {
      max-height: 42rem !important;
      font-size: 12px;
      border: 0.1rem solid #cbcbcb;
      border-radius: 0;
    }

    :deep(.multiselect__element) {
      width: 100%;
      font-size: 12px;
      border-bottom: 0.1rem solid #cbcbcb;
    }

    :deep(.multiselect__option) {
      // height: 4.2rem;
      // padding: 0 2rem;
      color: #363636;
      background: #fff;
    }

    :deep(.multiselect__option--highlight) {
      color: #363636;
      background: #fff;
    }

    :deep(.multiselect__option--selected) {
      color: #363636;
      background: #fff;
    }
  }

  hr {
    margin: 1.5rem 0;
    border-bottom: 0;
    opacity: 0.15;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 4.8rem;

    @media (min-width: 500px) {
      margin-top: auto;
    }

    &__button {
      margin-left: auto;
    }
  }

  .steps {
    font-size: 2.6rem;
  }

  .is-invalid {
    color: #ea5151;

    a {
      color: inherit;
    }
  }

  .p-search {
    &__group {
      position: relative;
    }

    &__control {
      padding-right: 5rem;
    }

    &__reset {
      position: absolute;
      top: calc((5.5rem / 2) - 0.5rem);
      right: 2rem;
      width: 1rem;
      height: 1rem;
      cursor: pointer;

      svg {
        display: block;
      }
    }
  }

  .p-metadata {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__image {
      max-width: 50%;
      margin-right: 1.6rem;
    }

    &__host {
      color: #666;
      font-size: 1.1rem;
      text-transform: uppercase;
    }

    &__title {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.3;
    }

    &__edit {
      position: absolute;
      top: 0;
      right: 0;
      width: 1.5rem;
      height: 1.5rem;
      background-image: url('assets/icons/pencil.svg');
      background-position: right top;
      background-size: contain;
      cursor: pointer;
    }
  }

  .p-tagmanager {
    position: relative;

    &__add {
      position: absolute;
      top: 1.7rem;
      right: 2rem;
      z-index: 100;
      color: $blue;
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-self: start;
    padding-left: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.2;

    &__edit {
      height: 1.5rem;
      padding-left: 1.5rem;
      cursor: pointer;

      svg {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
</style>
